import "./Button.scss";

const Button = ({ type, label }) => (
  <div className="button">
    <button type={type} className="button__link">
      {label}
    </button>
  </div>
);

export default Button;
