import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__container">
        <h2 className="footer__title">
          Designed & Created By: <br></br>Ralph Cabauatan © 2023
        </h2>
      </div>
    </div>
  );
};
export default Footer;
