import "./Sidebar.scss";
import { FaLinkedinIn, FaGithub } from "react-icons/fa";

const Sidebar = () => {
  return (
    <div className="sidebar">
      <div className="sidebar__left">
        <ul className="sidebar__left-list">
          <li className="sidebar__left-item">
            <a
              href="https://www.linkedin.com/in/ralphdaryn/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedinIn />
            </a>
          </li>
          <li className="sidebar__left-item">
            <a
              href="https://github.com/ralphdaryn/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub />
            </a>
          </li>
          <span className="sidebar__left-line" />
        </ul>
      </div>
      <div className="sidebar__right">
        <ul className="sidebar__right-list">
          <li className="sidebar__right-item">
            <a
              href="mailto:ralphdarync@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              ralphdarync@gmail.com
            </a>
          </li>
          <span className="sidebar__right-line"></span>
        </ul>
      </div>
    </div>
  );
};
export default Sidebar;
