import "./Header.scss";
import { FaCode, FaBars, FaTimes } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { motion } from "framer-motion";

const Header = () => {
  const [menuClicked, setMenuClicked] = useState(false);

  const menuVariants = {
    clicked: { rotate: 90, scale: 1.2 },
    unclicked: { rotate: 0, scale: 1 },
  };

  return (
    <div className="header">
      <div className="header__container">
        <h2 className="header__logo">
          <NavLink to="/" activeClassName="active" end>
            ralphdaryn
            <FaCode />
          </NavLink>
        </h2>
        <motion.div
          className="header__menu"
          onClick={() => setMenuClicked(!menuClicked)}
          variants={menuVariants}
          animate={menuClicked ? "clicked" : "unclicked"}
          whileHover={{ scale: 1.1 }}
          transition={{ duration: 0.3 }}
        >
          {menuClicked ? <FaTimes /> : <FaBars />}
        </motion.div>
        <div className={`mobile-menu ${menuClicked ? "show" : ""}`}>
          <ul>
            <li onClick={() => setMenuClicked(false)}>
              <NavLink to="/" activeClassName="active" end>
                Home
              </NavLink>
            </li>
            <li onClick={() => setMenuClicked(false)}>
              <NavLink to="/about" activeClassName="active">
                About
              </NavLink>
            </li>
            <li onClick={() => setMenuClicked(false)}>
              <NavLink to="/projects" activeClassName="active">
                Projects
              </NavLink>
            </li>
            <li onClick={() => setMenuClicked(false)}>
              <NavLink to="/contact" activeClassName="active">
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="header__navigation">
          <ul className="header__navigation-list">
            <li className="header__navigation-item">
              <NavLink to="/" activeClassName="active" end>
                Home
              </NavLink>
            </li>
            <li className="header__navigation-item">
              <NavLink to="/about" activeClassName="active">
                About
              </NavLink>
            </li>
            <li className="header__navigation-item">
              <NavLink to="/projects" activeClassName="active">
                Projects
              </NavLink>
            </li>
            <li className="header__navigation-item">
              <NavLink to="/contact" activeClassName="active">
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
