import "./Contact.scss";
import { useState } from "react";
import Button from "../Button/Button";

const Contact = () => {
  const [formData, setFormData] = useState({ name: "", email: "", message: "" });
  const [statusMessage, setStatusMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setStatusMessage("");

    try {
      const response = await fetch("/.netlify/functions/server/contact", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        console.log("Success:", data);
        setStatusMessage("Email sent successfully!");
        setFormData({ name: "", email: "", message: "" });
      } else {
        console.error("Error:", data);
        setStatusMessage("Something went wrong. Please try again.");
      }
    } catch (error) {
      console.error("Network error:", error);
      setStatusMessage("Network error. Please check your connection.");
    } finally {
      setIsSubmitting(false);
      scrollToStatusMessage();
    }
  };

  const scrollToStatusMessage = () => {
    const statusElement = document.querySelector(".contact__status");
    if (statusElement) {
      statusElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="contact">
      <div className="contact__container">
        <h2 className="contact__title">Contact</h2>
        <h3 className="contact__subtitle">Get In Touch.</h3>
        <p className="contact__subtext">Have questions? Leave a message :)</p>
        <div className="contact__wrapper">
          <form className="contact__form" onSubmit={handleSubmit}>
            <div className="contact__form-wrapper">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Your Name"
                required
                value={formData.name}
                onChange={handleInputChange}
                disabled={isSubmitting}
              />
            </div>

            <div className="contact__form-wrapper">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Your Email"
                required
                value={formData.email}
                onChange={handleInputChange}
                disabled={isSubmitting}
              />
            </div>

            <div className="contact__form-wrapper">
              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                name="message"
                placeholder="Your Message"
                rows="5"
                required
                value={formData.message}
                onChange={handleInputChange}
                disabled={isSubmitting}
              ></textarea>
            </div>

            <Button
              type="submit"
              label={isSubmitting ? "Sending..." : "Send"}
              disabled={isSubmitting}
            />
          </form>
          {statusMessage && <p className="contact__status">{statusMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default Contact;
