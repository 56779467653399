import "./Hero.scss";
import Button from "../Button/Button";
import rdynImg from "../../assets/images/rdyn.png";

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero__container">
        <h2 className="hero__title">About Me</h2>
        <div className="hero__wrapper">
          <div className="hero__content">
            <img className="hero__content-img" src={rdynImg} alt="rd pic" />
          </div>
          <div className="hero__section">
            <div className="hero__subtitle-text">
              Greetings! I'm <span className="hero__subtitle-name">Ralph Cabauatan. </span> 
              My journey into coding began during my college internship as a <span className="hero__subtitle-name">Web Developer</span> at a software testing company, where I discovered the excitement of building things from the ground up. Over the years, I've enhanced my skills through self-learning by creating projects, completing studies in software engineering and collaborating with people from diverse backgrounds.
            </div>
            <div className="hero__subtitle-text">
              I'm passionate about building web applications and I enjoy the process of problem-solving, where logical thinking meets creativity. I enjoy learning new things that challenge me to grow and create meaningful, user-friendly experiences.
            </div>
          </div>
        </div>
        <div className="hero__area">
          <h2 className="hero__area-title">My Skills</h2>
          <div className="hero__area-container">
            <div className="hero__skills">
              <h3 className="hero__skills-subtitle">Front End</h3>
              <p className="hero__skills-text">HTML/CSS</p>
              <p className="hero__skills-text">SASS</p>
              <p className="hero__skills-text">JavaScript</p>
              <p className="hero__skills-text">React</p>
              <p className="hero__skills-text">TypeScript</p>
              <p className="hero__skills-text">Next.js</p>
            </div>
            <div className="hero__skills">
              <h3 className="hero__skills-subtitle">Back End</h3>
              <p className="hero__skills-text">Node.js</p>
              <p className="hero__skills-text">Express.js</p>
              <p className="hero__skills-text">Python</p>
              <p className="hero__skills-text">REST API</p>
              <p className="hero__skills-text">JWT</p>
              <p className="hero__skills-text">OAuth</p>
            </div>
            <div className="hero__skills">
              <h3 className="hero__skills-subtitle">Database</h3>
              <p className="hero__skills-text">SQL</p>
              <p className="hero__skills-text">MySQL</p>
              <p className="hero__skills-text">PostgreSQL</p>
              <p className="hero__skills-text">Knex.js</p>
              <p className="hero__skills-text">MongoDB</p>
            </div>
            <div className="hero__skills">
              <h3 className="hero__skills-subtitle">CI/CD Tools</h3>
              <p className="hero__skills-text">Git/Github</p>
              <p className="hero__skills-text">Jenkins</p>
              <p className="hero__skills-text">Docker</p>
              <p className="hero__skills-text">Heroku</p>
              <p className="hero__skills-text">Netlify</p>
            </div>
          </div>
        </div>
        <div className="hero__button-container">
          {/* <a href="https://drive.google.com/file/d/1DJ4ucgCHVkv49tIIPcLOaNNSkiQMi4LI/view?usp=sharing">
            <Button label="View Resume" />
          </a> */}
            <a href="   https://drive.google.com/file/d/10QGJ5G2xxpzMPznecQMQ_8DZdP1L8oHx/view?usp=sharing">
            <Button label="View Resume" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Hero;