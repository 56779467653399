import "./Card.scss";
import movienator from "../../assets/images/movienator.jpg";
import tipplefusion from "../../assets/images/tipplefusion.jpg";
import jamming from "../../assets/images/jamming.jpg";
import kicksrus from "../../assets/images/kicksrus.jpg";
import stepbystep from '../../assets/images/stepbystep.jpg';

import {
  FaReact,
  FaHtml5,
  FaCss3Alt,
  FaSass,
  FaJs,
  FaNode,
  FaCodeBranch,
} from "react-icons/fa6";

const projects = [
  {
    img: stepbystep,
    title: "Step By Step Club",
    code: "https://github.com/ralphdaryn/stepbystepstars",
    description:
      "The Step By Step Club is a real-life web application for kids that promotes personal growth through guided activities and community support. It’s also a fitness club with a seamless event booking system, allowing children and parents to easily schedule workshops, classes and parties.",
    link: "https://stepbystepclub.ca",
    technologies: (
      <div className="card__technologies">
        <FaHtml5 />
        <FaCss3Alt />
        <FaSass />
        <FaJs />
        <FaReact />
        <FaNode/>
      </div>
    ),
  },
  {
    img: kicksrus,
    title: "Kicks R Us",
    code: "https://github.com/ralphdaryn/kicksrus",
    description:
      "An e-commerce website dedicated to members of an exclusive group to get the new release sneakers and latest fashion trend.",
    link: "https://kicksrus.netlify.app/",
    technologies: (
      <div className="card__technologies">
        <FaHtml5 />
        <FaCss3Alt />
        <FaSass />
        <FaJs />
      </div>
    ),
  },
  {
    img: movienator,
    title: "Movienator",
    code: "https://github.com/ralphdaryn/movienator",
    description:
      "A web application that utilizes the OMDb API to enable users search for a movie that displays the data and retrieve detailed information about the selected movie.",
    link: "https://themovienator.netlify.app/",
    technologies: (
      <div className="card__technologies">
        <FaHtml5 />
        <FaCss3Alt />
        <FaSass />
        <FaJs />
        <FaReact />
      </div>
    ),
  },
  {
    img: jamming,
    title: "Jamming",
    code: "https://github.com/ralphdaryn/jamming",
    description:
      "Music web application that creates a playlist using your personal Spotify account leveraging the Spotify Web API. Published using Surge through NPM.",
    link: "http://slippery-drain.surge.sh",
    technologies: (
      <div className="card__technologies">
        <FaHtml5 />
        <FaCss3Alt />
        <FaSass />
        <FaJs />
        <FaReact />
        <FaNode />
      </div>
    ),
  },
  {
    img: tipplefusion,
    title: "Tipple Fusion",
    code: "https://github.com/ralphdaryn/tipplefusion",
    description:
      "A web application that helps you find the recipe of your favourite cocktail drink with the assistance of the CocktailDB API.",
    link: "https://tipplefusion.netlify.app",
    technologies: (
      <div className="card__technologies">
        <FaHtml5 />
        <FaCss3Alt />
        <FaSass />
        <FaJs />
        <FaReact />
      </div>
    ),
  },

];

const Card = () => {
  return (
    <div className="card">
      <h2 className="card__title">Projects I've Built</h2>
      {projects.map((project, index) => (
        <div className="card__container" key={index}>
          <img className="card__image" src={project.img} alt={project.title} />
          <div className="card__middle">
            <div className="card__text">
              <a href={project.link} target="_blank" rel="noopener noreferrer">
                View Demo
              </a>
            </div>
          </div>
          <div className="card__wrapper">
            <p className="card__subtext">Featured Project</p>
            <h2 className="card__subtitle">{project.title}</h2>
            <p className="card__description">{project.description}</p>
            <p className="card__language">{project.technologies}</p>
            <p className="card__info">
              <a
                href={project.code}
                className="card__link"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Code <FaCodeBranch />
              </a>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};
export default Card;
