import React, { useRef, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { motion } from "framer-motion";
import "./App.scss";
import Header from "./components/Header/Header";
import Main from "./components/Main/Main";
import Sidebar from "./components/Sidebar/Sidebar";
import Hero from "./components/Hero/Hero";
import Projects from "./components/Projects/Projects";
import Card from "./components/Card/Card";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";

const useInView = () => {
  const ref = useRef(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const checkInView = () => {
      const rect = ref.current.getBoundingClientRect();
      const elemTop = rect.top;
      const elemBottom = rect.bottom;
      const isVisible = elemTop < window.innerHeight && elemBottom >= 0;

      setInView(isVisible);
    };

    window.addEventListener("scroll", checkInView);

    // Initial check
    checkInView();

    return () => {
      window.removeEventListener("scroll", checkInView);
    };
  }, []);

  return [ref, inView];
};

const springIn = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 150, damping: 100 },
  },
};

const ScrollFade = ({ children }) => {
  const [ref, inView] = useInView();

  return (
    <motion.div
      ref={ref}
      variants={springIn}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
    >
      {children}
    </motion.div>
  );
};

const App = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <Sidebar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Main />
                <ScrollFade>
                  <Hero />
                </ScrollFade>
                <ScrollFade>
                  <Projects />
                </ScrollFade>
                <ScrollFade>
                  <Card />
                </ScrollFade>
                <ScrollFade>
                  <Contact />
                </ScrollFade>
              </>
            }
          />
          <Route
            path="/about"
            element={
              <ScrollFade>
                <Hero />
              </ScrollFade>
            }
          />
          <Route
            path="/projects"
            element={
              <>
                <ScrollFade>
                  <Projects />
                </ScrollFade>
                <ScrollFade>
                  <Card />
                </ScrollFade>
              </>
            }
          />
          <Route
            path="/contact"
            element={
              <ScrollFade>
                <Contact />
              </ScrollFade>
            }
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
